import React from 'react';
import Layout from 'components/layout';
import SEO from 'components/seo';
import { Router } from '@reach/router';
import { Trailer } from 'views';
import Route from 'components/route';
import useLogRocket from 'hooks/useLogRocket';

const IndexPage = () => {
  useLogRocket();
  return (
    <Layout>
      <SEO title="Home" />
      <Router>
        <Route path="/" component={() => <Trailer withPurchaseOption />} />
      </Router>
    </Layout>
  );
};

export default IndexPage;
